import { cn } from "~/lib/utils";

export default function Spinner({ spin = true, className }: { spin?: boolean; className?: string }) {
  const classes = cn(`h-4 w-4 ${spin ? "animate-spin" : ""}`, className);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" className={classes}>
      <g fill="none">
        <path
          d="M10 3a7 7 0 0 0-7 7a.5.5 0 0 1-1 0a8 8 0 1 1 8 8a.5.5 0 0 1 0-1a7 7 0 1 0 0-14z"
          fill="currentColor"
        ></path>
      </g>
    </svg>
  );
}
